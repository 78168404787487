import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";
import userManagerAgentApi from "@/api/userManagerAgentApi";

export const programAgents = {
    state: () => ({
        programAgents: undefined,
    }),
    getters: {
        getAll: (state) => {
            return state.programAgents
        },
        getAgentInfo: (state) => (agentId) => {
            if (state.programAgents === undefined || agentId === undefined)
                return undefined
            for (const agentsListKey in state.programAgents) {
                const agent = state.programAgents[agentsListKey];
                if (agent.id == agentId) {
                    return agent
                }
            }
            return undefined;
        },
        getAgentHealth: (state, getters) => (agentId) => {
            return getters.getAgentInfo(agentId).health
        }
    },
    mutations: {
        setProgramAgents(state, agents) {
            state.programAgents = agents
        },
        addProgramAgent(state, agent) {
            if(state.programAgents === undefined)
                state.programAgents = []
            state.programAgents.programAgents.push(agent)
        },
    },
    actions: {
        loadAll({state, commit}, {ok, error}) {
            userManagerAgentApi.loadAllProgramAgents(function (value) {
                commit("setProgramAgents", value.data)
                ok()
            }, function (r) {
                error()
            })
        },
        loadOneAgent({state, commit}, {id, ok, error}){
            userManagerAgentApi.loadInfoProgramAgents(id, function (value) {
                commit("addProgramAgent", value.data)
                ok()
            }, function (r) {
                error()
            })
        },
        loadHealth({state, commit, getters}, {id, ok, error}) {
            userManagerAgentApi.loadHealthInfoProgramAgents(id,
                function (result) {
                    getters.getAgentInfo(id).health = result.data;
                    ok();
                },
                function (r) {
                    console.log(r)
                    error();
                })
        }
    },
    namespaced: true
}