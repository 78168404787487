<template>
  <v-container>
    <v-row>
      <v-col cols-12>
        <v-card>
          <v-card-title v-if="profileUser">
            <v-row>
              <v-col cols="3">
                <b>Ваш баланс: </b>
              </v-col>
              <v-col>
                {{ profileUser.wallet }} рублей
              </v-col>
              <v-col>
                <v-btn variant="outlined" block @click="openDialog = true">
                  Пополнить
                </v-btn>

                <v-dialog v-model="openDialog" class="dialog-request">
                  <v-card :loading="loadingRefill">
                    <v-card-title>
                      Пополнение
                    </v-card-title>
                    <v-card-actions>
                      <v-text-field
                          v-model="amount"
                          block
                          label="Сумма"
                          placeholder="Сумма в рублях">

                      </v-text-field>
                    </v-card-actions>
                    <v-card-actions>
                      <v-row>
                        <v-col>
                          <v-btn block variant="outlined" :loading="loadingRefill" @click="reqRefillBalance">
                            Подтвердить
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn block variant="outlined" @click="openDialog = false">
                            Отмена
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <card-history-balance/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";
import userProfileData from "@/mixins/userProfileData";
import CardHistoryBalance from "@/components/profile/CardHistoryBalance.vue";
import isAuthViewRedirect from "@/mixins/isAuthViewRedirect";

export default {
  name: "UserWalletPage",
  components: {CardHistoryBalance},
  mixins: [userProfileData, isAuthViewRedirect],
  data() {
    return {
      amount: 0,
      openDialog: false,
      loadingRefill: false
    }
  },
  methods: {
    reqRefillBalance() {
      this.loadingRefill = true

      const pathRefill = config.api + "/user/profile/balance/refill";
      axios.post(pathRefill, {
        amount: this.amount
      }, {
        headers: api.getHeadersLogin()
      })
          .then(value => {
            const data = value.data;
            window.open(data.redirectUrl);

            this.loadingRefill = false;
            this.openDialog = false;
          })
          .catch(reason => {
            this.loadingRefill = false;
          })
    }
  }
}
</script>

<style scoped>

.dialog-request {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
}

@media (min-width: 890px) {
  .dialog-request {
    display: block;
    margin: auto;
    width: 50%;
    height: 100%;
  }
}
</style>