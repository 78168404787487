<template>
  <v-container>
    <v-row class="d-md-flex justify-center">
      <v-col class="v-col-12">
        <v-card>
          <v-card-actions>
            <v-row class="align-center">
              <v-col class="v-col-sm-8 v-col-12">
                <v-text-field placeholder="Поиск" v-model="findProgramAgent" class="mt-3">
                </v-text-field>
              </v-col>
              <v-col class="v-col-sm-4 v-col-12">
                <v-btn :disabled="loadingProcess" block variant="outlined" @click="updateAgents">
                  Обновить список
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="v-col-12" v-if="isAuth && isAdmin">
        <v-card>
          <v-card-actions>
            <v-btn block variant="outlined" to="/create-program-agent">
              Создать нового бота
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-row v-if="(filterProgramAgentList === undefined || filterProgramAgentList.length === 0) && loadingProcess">
      <v-col>
        <v-progress-linear indeterminate
                           color="green darken-10">

        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="(filterProgramAgentList === undefined || filterProgramAgentList.length === 0) && !loadingProcess">
      <v-col>
        <v-card>
          <v-card-text class="text-center">
            <span v-if="isAuth">Нет доступных агентов</span>
            <span v-if="!isAuth">Отсутствуют агенты для общего доступа</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!!filterProgramAgentList">
      <v-col v-if="typeViewList === 'card'" class="v-col-12 v-col-md-6" v-for="agent in sortingProgramAgent"
             key="agent.id">
        <program-agent-description :bot="agent" :preview="true" :link="'/program-agent/' + agent.id"/>
      </v-col>
<!--      <v-col v-if="typeViewList === 'table'" class="v-col-12">-->
<!--        <v-card>-->
<!--          <v-card-text>-->
<!--            <v-row v-for="agent in sortingProgramAgent" key="agent.id">-->

<!--              <v-col class="agent-line" @click="router().push('/program-agent/' + agent.id)">-->
<!--                <v-row>-->
<!--                  <v-col class="v-col-1">-->
<!--                    <span>-->
<!--                      <span v-if="agent.online" style="color: green">◉</span>-->
<!--                      <span v-if="!agent.online" style="color: indianred">◉</span>-->
<!--                    </span>-->
<!--                  </v-col>-->
<!--                  <v-col>-->
<!--                    <span><b>{{ agent.name }}</b></span>-->
<!--                  </v-col>-->
<!--                  <v-col class="description">-->
<!--                    {{ agent.description }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-col>-->


<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import ProgramAgentDescription from "@/components/ProgramAgentDescription";
import {mapState, mapMutations, mapGetters, mapActions} from "vuex"
import userProfileData from "@/mixins/userProfileData";
import router from "@/router";

export default {
  name: "ProgramAgentListView",
  components: {ProgramAgentDescription},
  mixins: [userProfileData],
  data() {
    return {
      loadingProcess: false,
      findProgramAgent: "",
      typeViewList: "card"
    }
  },
  computed: {
    ...mapState({
      programAgentsList: state => state.programAgents.programAgents,
      isAuth: state => state.profile.isAuth
    }),
    sortingProgramAgent() {
      if (!!this.programAgentsList) {
        return this.filterProgramAgentList
            .sort((a, b) => {
              // Сравниваем онлайн статус
              if (a.online === b.online) {
                // Если статус одинаковый, сортируем по id
                return b.id - a.id;
              }
              // Ставим online в начале
              return a.online ? -1 : 1; // если a.online true, возвращаем -1
            })
      }
      return this.programAgentsList
    },
    filterProgramAgentList() {
      if (!!this.programAgentsList) {
        return this.programAgentsList.filter((agent) => {
          return JSON.stringify(agent).toLowerCase().includes(this.findProgramAgent.toLowerCase());
        });
      }
      return this.programAgentsList
    }
  },
  methods: {
    router() {
      return this.$router
    },
    ...mapActions({
      loadAll: "programAgents/loadAll"
    }),
    loadAgents() {
      this.loadingProcess = true
      this.loadAll({
        ok: () => {
          this.loadingProcess = false
        },
        error: () => {
          setTimeout(() => {
            this.loadingProcess = false
          }, 700)
        }
      })
    },
    updateAgents() {
      this.loadAgents()
    }
  },
  mounted() {
    this.loadAgents()
  }
}
</script>

<style scoped>
.agent-line {
  border: 1px solid #423f3f;
  border-radius: 3px;
  margin: 2px 0;
  padding: 5px;
}

.agent-line:hover{
  background-color: #c5c5c5;
  cursor: pointer;
}

.agent-line .description {
  text-overflow: ellipsis;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
}
</style>