<template>
  <v-app>
    <navbar-view></navbar-view>
    <v-main>
      <v-container class="pa-0 pa-md-6 pa-lg-8" style="max-width: 1280px">
        <router-view/>
      </v-container>
    </v-main>
    <NotificationShow/>
  </v-app>
</template>

<script>


import NavbarView from "@/views/NavbarView";
import NotificationShow from "@/components/NotificationShow.vue";
import {mapActions} from "vuex";
import loginTokenManager from "@/api/loginTokenManager";

export default {
  name: 'App',
  components: {
    NotificationShow,
    NavbarView
  },
  methods: {
    ...mapActions({
      login: "profile/login",
      loadProfile: "profile/loadProfile"
    }),
  },
  created() {
    console.log("Valid login: " + this.login);
    if (loginTokenManager.isLoginValid()) {
      this.loadProfile({
        ok: (data) => {
          if (window.location.href.includes("/not-found"))
            history.back()
        }, error: (r) => {
          console.log(r)
        }
      });
    }
  },
  data: () => ({
    showInfo: false
    //
  }),
}
</script>
