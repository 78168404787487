const NAME_AUTH_STORAGE = "authData"

function writeLocalStore(data) {
    if (data == null)
        localStorage.removeItem(NAME_AUTH_STORAGE)
    else
        localStorage.setItem(NAME_AUTH_STORAGE, JSON.stringify(data));
}

function loadLocalStore() {
    return JSON.parse(localStorage.getItem(NAME_AUTH_STORAGE));
}

const module = {
    authData: undefined,
    setAuthData(data) {
        console.log("Auth: ");
        console.log(data);
        this.authData = data;
        writeLocalStore(data);
    },
    getOrLoadData() {
        if (this.authData === undefined)
            this.setAuthData(loadLocalStore())
        return this.authData;
    },
    isLoginValid() {
        const data = this.getOrLoadData()
        if (data === undefined || data == null) {
            writeLocalStore(null);
            return false;
        }
        if (new Date() > new Date(data.expires)) {
            console.log("Время жизни прошло")
            writeLocalStore(null);
            return false;
        }
        return true;
    },
    token() {
        if (!this.isLoginValid()) {
            return null;
        }
        return this.getOrLoadData().token;
    }
}

export default module;