import config from "@/api/config";
import axios from "axios";

const apiLogin = config.api + '/user/login';
const apiLoadProfile = config.api + '/user/profile';
const apiUserRegistration = config.api + '/user/registration';

import loginTokenManager from "@/api/loginTokenManager";

const api = {
    getHeadersLogin: function () {
        return {
            "token": loginTokenManager.token()
        }
    },
    executeDefaultGet: function (path, ok, error) {
        axios.get(path, {
            headers: this.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    executeDefaultGetParam: function (path, param, ok, error) {
        axios.get(path, {
            params: param,
            headers: this.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    executeDefaultPost: function (path, data, ok, error) {
        axios.post(path, data, {
            headers: this.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    login: function (email, password, ok, error) {
        axios.post(apiLogin, {
            email: email,
            password: password
        }, {}).then(value => {
            loginTokenManager.setAuthData(value.data);
            ok(value);
        }).catch(reason => {
            error(reason);
        })
    },
    registration(data, ok, error) {
        axios.post(apiUserRegistration, data, {}).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    loadUserProfile: function (ok, error) {
        this.executeDefaultGet(apiLoadProfile, ok, error);
    },
}


export default api