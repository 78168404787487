<template>
  <v-card v-if="!!bot" :class="{'agent-card': preview}" @click="preview ? router().push(link) : 0">
    <v-card-title class=" d-flex justify-space-between">
      <span>
        <span v-if="bot.online" style="color: green">◉</span>
        <span v-if="!bot.online" style="color: indianred">◉</span>
        {{ bot.name }}
      </span>
      <v-btn v-if="!preview" icon="mdi-reload" size="small" @click="updateDataFunc()">
      </v-btn>
    </v-card-title>
    <v-card-text class="">
      {{ bot.description }}
    </v-card-text>
  </v-card>
</template>

<script>
import usersMethod from "@/mixins/usersMethod";
import {mapGetters} from "vuex";

export default {
  name: "ProgramAgentDescription",
  mixins: [usersMethod],
  data() {
    return {}
  },
  props: {
    bot: Object,
    preview:
        {
          type: Boolean,
          default: false
        },
    link: String,
    updateDataFunc: {
      type: Function, default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      getUser: "users/getUserById",
    }),
  },
  methods: {
    router() {
      return this.$router
    },
  }
}
</script>

<style scoped>
.agent-card {
}

.agent-card:hover {
  background-color: #c5c5c5;
  cursor: pointer;
}
</style>